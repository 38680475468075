<template>
  <lesson-quiz-preview
    v-if="isQuizSection"
    id="lesson-section-detail"
    :title="`Học phần Quiz ${form.name}`"
    :question-list="form.questionList"
    :question-num="
      form.selectedQuestionNum && form.selectedQuestionNum.value
        ? form.selectedQuestionNum.value
        : form.selectedQuestionNum
    "
  />
  <b-modal
    id="lesson-section-detail"
    v-else
    :title="`Học phần ${form.code}`"
    centered
  >
    <h5>{{ form.name }}</h5>
    <div class="mt-4 text-center">
      <b-img-lazy
        v-if="form.imagePreview"
        fluid
        blank
        blank-color="#bbb"
        :src="form.imagePreview"
      />
      <div v-if="form.imageTitle" class="font-italic text-center my-2">
        {{ form.imageTitle }}
      </div>
    </div>
    <div
      class="lesson-section-detail__content mt-4"
      v-html="form.firstContent"
    />
    <div class="mt-4">
      <video
        v-if="form.videoUrl && !isValidYoutubeLink()"
        :src="form.videoUrl"
        class="w-100"
        controls
      ></video>
      <iframe v-if="form.videoUrl && isValidYoutubeLink()"
        class="w-100 mb-4"
        height="315"
        :src="youtubeEmbedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div v-if="form.videoTitle" class="font-italic text-center my-2">
        {{ form.videoTitle }}
      </div>
    </div>
    <div
      v-if="form.secondContent"
      class="lesson-section-detail__content mt-4"
      v-html="form.secondContent"
    />
    <div class="mt-4">
      <audio
        v-if="form.audioUrl"
        :src="form.audioUrl"
        class="w-100"
        controls
      ></audio>
      <div v-if="form.audioTitle" class="font-italic text-center my-2">
        {{ form.audioTitle }}
      </div>
    </div>
    <template slot="modal-footer">
      <div class="d-flex justify-content-end align-items-center">
        <b-button class="btn mr-3" @click="onClickCancelButton"> Hủy</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import LessonQuizPreview from './LessonQuizPreview';

export default {
  name: 'LessonSectionDetail',
  components: { LessonQuizPreview },
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      url: {
        detail: '/LessonSection',
        prepare: '/LessonSection/PrepareCreateUpdateFormItem',
      },
      form: {
        id: null,
        code: null,
        name: null,
        type: null,
        status: null,
        firstContent: null,
        secondContent: null,
        videoFile: null,
        videoUrl: null,
        linkType: 0,
        videoTitle: null,
        audioFile: null,
        audioUrl: null,
        audioTitle: null,
        imageFile: null,
        imageTitle: null,
        imagePreview: null,
        minCompletePercent: null,
        questionNum: 0,
        selectedQuestionNum: null,
        questionList: [],
      },
    };
  },
  watch: {
    section: {
      deep: true,
      async handler(val) {
        if (val.id) {
          await this.loadPrepareDataSection();
        } else {
          this.form = { ...val };
        }
        this.$emit('view');
      },
    },
  },
  computed: {
    isQuizSection() {
      return this.section.type == 4;
    },
    youtubeEmbedUrl() {
      return `https://www.youtube.com/embed/${this.getVideoIdFromUrl()}`;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('lesson-section-detail');
    },
    async loadPrepareDataSection() {
      let { data } = await this.$api.get(
        `${this.url.prepare}/${this.section.id}`,
      );
      this.form.id = data.id;
      this.form.code = data.code;
      this.form.name = data.name;
      this.form.status = data.status;
      this.form.type = data.type;
      this.form.firstContent = data.firstContent;
      this.form.secondContent = data.secondContent;
      this.form.videoUrl = data.videoAddressLink;
      this.form.videoTitle = data.videoDescription;
      this.form.audioUrl = data.audioAddressLink;
      this.form.audioTitle = data.audioDescription;
      this.form.imageTitle = data.imageTitle;
      this.form.imagePreview = data.image.url;

      // load quiz
      let quizRes = await this.$api.get(
        `${this.url.detail}/${this.section.id}`,
      );
      let quizLessonSections = quizRes.data.quizLessonSections;
      this.form.questionList = quizLessonSections.map((q) => q.quiz) || [];
      this.form.selectedQuestionNum = quizLessonSections
        ? quizLessonSections.length
        : 0;
    },
    onClickCancelButton() {
      this.closeModal();
    },
    isValidYoutubeLink() {
      const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
      const isValidYoutubeLink = youtubeRegex.test(this.form.videoUrl);
      return isValidYoutubeLink;
    },
    getVideoIdFromUrl() {
      const match = this.form.videoUrl.match(/[?&]v=([^&]+)/);
      return match ? match[1] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-section-detail__content {
  word-wrap: break-word;
  font-size: 1.2rem;
}
</style>
