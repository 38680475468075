<template>
  <b-modal :id="id" :title="title" centered size="md">
    <div class="lesson-quiz-review__total">
      Tổng số câu hỏi: {{ questionNum || 0 }} câu
    </div>
    <div
      class="lesson-quiz-review__quiz"
      v-for="question in questionList"
      :key="question.id"
    >
      <b-container>
        <b-row class="lesson-quiz-review__quiz__header">
          <b-col cols="9">
            <span>{{ question.name }}</span>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="3">
            <span>Câu đúng</span>
          </b-col>
        </b-row>
        <b-row
          class="lesson-quiz-review__quiz__answer"
          v-for="answer in question.quizAnswers"
          :key="answer.id"
        >
          <b-col cols="9">
            <span>{{ answer.name }}</span>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="3">
            <b-form-radio
              class="radio-answer"
              v-if="question.type === 1"
              v-model="answer.isCorrect"
              :value="true"
              :name="`radio-answer-${answer.id}`"
              disabled
            />
            <b-form-checkbox
              v-else
              v-model="answer.isCorrect"
              class="checkbox-answer"
              disabled
              :name="`checkbox-answer-${answer.id}`"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <template slot="modal-footer">
      <div class="d-flex justify-content-end align-items-center">
        <b-button class="btn mr-3" @click="onClickCancelButton"> Hủy</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'LessonQuizPreview',
  props: {
    id: {
      type: String,
      default: 'lesson-quiz-review',
    },
    title: {
      type: String,
      default: '',
    },
    questionList: {
      type: Array,
      default: () => [],
    },
    questionNum: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    onClickCancelButton() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.lesson-quiz-review__total {
  font-weight: bold;
  margin-bottom: 20px;
}

.lesson-quiz-review__quiz {
  margin-bottom: 24px;

  .lesson-quiz-review__quiz__header {
    background-color: #f3f6f9;

    span {
      font-weight: bold;
      color: #888c9f;
    }
  }

  .lesson-quiz-review__quiz__answer {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

    .checkbox-answer .custom-control-label::before,
    .checkbox-answer .custom-control-label::after {
      left: -1.55rem;
      width: 1.5rem;
      height: 1.5rem;
    }
    .radio-answer .custom-control-label::before,
    .radio-answer .custom-control-label::after {
      width: 1.5rem;
      height: 1.5rem;
      left: -1.55rem;
    }

    .radio-answer
      .custom-control-input:checked
      ~ .custom-control-label::before {
      border-color: #01645a !important;
      background-color: #01645a !important;
    }
  }
}

.checkbox-answer
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #01645a !important;
}
</style>
